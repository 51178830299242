document.addEventListener('turbolinks:load', () => {
  if ($('.available-credits').length) {
    availableCredits()
  }
})

function availableCredits () {
  const totalBeforeTax = parseFloat($('#order_total_before_tax').val())
  const totalStoreCredits = parseFloat($('#order_total_available_store_credit').val())

  $(document).on('click', '#available-credits-apply-button', (e) => {
    e.preventDefault()

    const storeCreditsApplied = roundDecimal(parseFloat($('#order_store_credits_applied').val()))
    const newTotalBeforeTax = roundDecimal(totalBeforeTax - storeCreditsApplied)
    const errorClass = 'field_with_errors'

    if (storeCreditsApplied > totalStoreCredits || newTotalBeforeTax < 0) {
      $('.available-credits__wrapper').addClass(errorClass)
    } else {
      $('.available-credits__wrapper').removeClass(errorClass)
      $('#order_payment_amount').val(storeCreditsApplied)
      $.ajax({
        type: 'GET',
        url: '/checkout/store_credits_applied_update',
        data: { order: { store_credits_applied: storeCreditsApplied } },
      })
    }
  })

  function roundDecimal (amount) {
    if (!amount) return 0.0

    return Math.round(amount * 100) / 100
  }
}
