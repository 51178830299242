import { Simulator } from "./simulator.js";

document.addEventListener("turbolinks:load", () => {
  if ($("#product-details").length === 0) {
    return;
  }

  let imgUploader = document.getElementById("product-img-uploader")
  if (imgUploader) imgUploader.chosen = false;

  registerEnforceUserManualSelect();
  registerPriceTableListeners();

  const simulatorEl = document.getElementById("simulator-wrapper");
  if (simulatorEl) new Simulator(simulatorEl);

  // This wasnt being used, but i'll keep it here.
  const productCounter = $(".product-counter").length;
  localStorage.setItem("product-options", "initialized");

  let clearButton = document.getElementById('clear-selection-button')
  clearButton.addEventListener('click', (e) => clearSelections(e))
});

function setPrice(priceSt) {
  const price = parseFloat(priceSt).toFixed(2);

  // TODO: temporary, just presentation
  const priceSubtotalEl = document.getElementById("price-subtotal");
  const priceIvaEl = document.getElementById("price-iva");
  const priceTotalEl = document.getElementById("price-total");

  const TAX = 0.23;
  priceSubtotalEl.innerHTML = `${price} €`;
  priceIvaEl.innerHTML = `${(price * TAX).toFixed(2)} €`;
  priceTotalEl.innerHTML = `${(price * (1 + TAX)).toFixed(2)} €`;
}

function populateSimulatorInfo(jres) {
  document.dispatchEvent(
    new CustomEvent("simulation-data-updated", { detail: jres })
  );
}

async function updatePricePreview() {
  const optionValuesForm = document.getElementById("cart_form");
  const data = new FormData(optionValuesForm);
  const res = await fetch("/orders/price_preview", {
    method: "POST",
    body: data,
  });

  const jres = await res.json();
  if ("price" in jres && "total" in jres.price) setPrice(jres.price.total);

  populateSimulatorInfo(jres);
}

function registerEnforceUserManualSelect() {
  // chosenOptions is a Map defined in _dynamic_variant_cart_form
  // each (key,value) contain [option_type.id , {chosen: false ,presentation: option_type.presentation}]

  const inputs = document.querySelectorAll("input[type='radio']");

  // For each input, register a event handler that marks the option
  // as chosen on input
  for (const i of inputs) {
    i.addEventListener("input", (e) => {
      console.log("fired")
      checkDisabledOptions();
      const optionTypeId = parseInt(
        e.target.attributes["data-option-type-id"].value
      );
      chosenOptions.get(optionTypeId).chosen = true;
      if (allOptionsChosen()) updatePricePreview();
    });
  }

  const selects = document.querySelectorAll(".custom-select-block select");
  for (const i of selects) {
    i.addEventListener("change", (e) => {
      checkDisabledOptions();
      // id will be options_ID
      const optionTypeId = parseInt(e.target.id.split("_")[1]);
      chosenOptions.get(optionTypeId).chosen = true;
      if (allOptionsChosen()) updatePricePreview();
    });
  }

  const quantityInput = document.querySelector(".input-field__input--quantity");
  quantityInput?.addEventListener("change", (e) => {
    checkDisabledOptions();
    const optionTypeRow = e.target.closest(".product-options__row");
    const optionTypeId = parseInt(optionTypeRow.id.split("_")[2]);
    chosenOptions.get(optionTypeId).chosen = true;
    if (allOptionsChosen()) updatePricePreview();
  });
  quantityInput?.dispatchEvent(new Event("change")); // this comes pre-selected at 1

  // When submiting the form, check if one of each option type is chosen
  // If not, prevent the default submit form
  document.getElementById("btn-submit").addEventListener("click", (e) => {
    let imgUploader = document.getElementById("product-img-uploader");

    // This cannot be inside the boolean expression beacause it will short
    // circuit and wont update the uploader styles (updaten on .chosen property access)
    const imageUploaderChosen = imgUploader.uploader.chosen;

    if (!(allOptionsChosen() && imageUploaderChosen)) {
      for (let element of missingElements()) {
        element.classList.add("product-options__row--not-selected");
      }

      document.getElementById("product-error-message").style.display = "block";
      document.querySelector(".product-options").style.marginTop = "24px";
      var promoBar = document.getElementById("promo-bar");
      var promoBarHeight;

      if (promoBar) {
        promoBar.style.position = "fixed";
        promoBarHeight = promoBar.offsetHeight;
      } else {
        promoBarHeight = 0;
      }

      var topBar = document.getElementById("top-bar");

      var offset = promoBarHeight + topBar.offsetHeight; // sticky nav height
      window.scroll({
        top:
          document.getElementById("product-error-message").offsetTop - offset,
        left: 0,
        behavior: "smooth",
      });

      e.preventDefault();
    }
  });
}

function allOptionsChosen() {
  let all = true;
  for (const [_id, option] of chosenOptions) all &&= option.chosen;
  return all;
}

function missingElements() {
  const arr = new Array();
  for (const [_id, option] of chosenOptions)
    if (!option.chosen) arr.push(document.getElementById(option.element));
  return arr;
}

function registerPriceTableListeners() {
  const selectors = document.getElementsByClassName(
    "property-thumbnail__input"
  );

  // If there's only one option, pre-select it. Otherwise, add the listener
  for (const selector of selectors) {
    if (document.getElementsByName(selector.name).length == 1) {
      selector
        .closest(".product-options__row")
        .classList.add("product-options__row--selected");
      selector.checked = true;
      const values = returnValues(selector);
      setSummaryRowText(values[0], values[1]);

      selector.dispatchEvent(new Event("input"));
    } else {
      selector.addEventListener("change", (e) => changeSummary(e));
    }
  }
  const quantitySelect = document.querySelector(
    "#inside-product-cart-form [selector=hiddenSelect] select"
  );
  // Sometimes there is no quantity selector
  quantitySelect?.addEventListener("change", (e) => changeSummarySelect(e));

  const quantityInput = document.querySelector(".input-field__input--quantity");
  quantityInput?.addEventListener("change", (e) => changeSummaryInput(e));
  // This input starts as being selected
  quantityInput?.dispatchEvent(new Event("change"));
}
// TODO: The three changeSummary functions are very simillar. Maybe refactor into
// a class? or some kind of template pattern? they are all doing basically the
// same, the only diference is how they get the optionTypeId (which I belive could)
// be the same, but dont have the time rn to fix
function changeSummary(e) {
  markParentSelected(e.target);
  const values = returnValues(e.target);
  const price = parseFloat(e.target.attributes["data-price"].nodeValue);
  setSummaryRowText(values[0], values[1]);

  scrollToNextSelected();
  localStorage.removeItem("product-options");
}

function changeSummarySelect(e) {
  markParentSelected(e.target);
  const optionTypeId = e.target.id.split("_")[1];
  const summary_row = document.getElementById(`summary_${optionTypeId}`);
  const text = e.target.selectedOptions[0].text;

  setSummaryRowText(summary_row, text);
  if (!localStorage.getItem("product-options")) {
    scrollToNextSelected();
  }
}

function changeSummaryInput(e) {
  const parentElement = markParentSelected(e.target);
  // TODO: remove the dependency on the parent id
  const optionTypeId = parentElement.id.split("_")[2];
  const summary_row = document.getElementById(`summary_${optionTypeId}`);
  const text = e.target.value;

  setSummaryRowText(summary_row, text);
  // if (!localStorage.getItem('product-options')) {
  //   scrollToNextSelected()
  // }
}

function markParentSelected(element) {
  const parentElement = element.closest(".product-options__row");
  parentElement.classList.remove("product-options__row--not-selected");
  parentElement.classList.add("product-options__row--selected");
  return parentElement;
}

function scrollToNextSelected() {
  var promoBar = document.getElementById("promo-bar");
  var promoBarHeight;

  if (promoBar) {
    promoBar.style.position = "fixed";
    promoBarHeight = promoBar.offsetHeight;
  } else {
    promoBarHeight = 0;
  }

  var topBar = document.getElementById("top-bar");

  var offset = promoBarHeight + topBar.offsetHeight; // sticky nav height

  $(".product-options__row").each(function (index) {
    if (!$(this).hasClass("product-options__row--selected")) {
      window.scroll({
        top: $(this).offset().top - offset,
        left: 0,
        behavior: "smooth",
      });
      return false;
    } else if (
      $(".product-options__row").length - 1 === index &&
      $(this).hasClass("product-options__row--selected")
    ) {
      window.scroll({
        top: $("#step-download").offset().top - offset,
        left: 0,
        behavior: "smooth",
      });
      return false;
    }
  });
}

function setSummaryRowText(row, text) {
  row.innerHTML = text;
  row.parentElement.getElementsByTagName("i")[0].style.visibility = "visible";
}

function returnValues(selected) {
  const optionTypeId = selected.attributes["data-option-type-id"].nodeValue;
  const summary_row = document.getElementById(`summary_${optionTypeId}`);
  const presentation = selected.attributes["data-presentation"].nodeValue;
  return [summary_row, presentation];
}

async function checkDisabledOptions() {
  let form = document.getElementById("cart_form");
  let data = new FormData(form);
  let product_id = document.getElementById("product_id").value;

  let res = await fetch(`/products/${product_id}/blocked_option_values`, {
    method: "POST",
    body: data,
  });

  if (res.status == 200) {
    let jres = await res.json();
    console.log(jres);
    for (let ov of jres["enabled"]) {
      let id = ov.id;
      let input = document.querySelector(`input[value='${id}'`);
      if (!input) {
        // console.log(`No input for id ${id}`)
        continue
      }
      if (input && input.parentElement.id != "inside-product-cart-form")
        input.parentElement.parentElement.style.opacity = "1";
      input.parentElement.style.cursor = "pointer";
      input.disabled = false;
    }

    for (let ov of jres["disabled"]) {
      let id = ov.id;
      let input = document.querySelector(`input[value='${id}'`);
      if (input == null)
        continue;

      input.parentElement.parentElement.style.opacity = "0.3";
      input.parentElement.style.cursor = "not-allowed";
      input.disabled = true;

      
      if (input.checked){
        let id = parseInt(input.attributes["data-option-type-id"].value)
        let otElement = document.getElementById(chosenOptions.get(id).element)
        disableSection(otElement)
      }
    }
  }
}

function clearSelections(e) {

  for (let otElement of optionTypeElements) 
    disableSection(otElement)

  checkDisabledOptions()
}

function disableSection(optionTypeEl){
  let inputs = optionTypeEl.querySelectorAll("input[type='radio']")
  if (inputs.length == 1) return

  for (let input of inputs) {
    input.checked = false
  }

  let id = optionTypeEl.id.split("_")[2]
  chosenOptions.get(parseInt(id)).chosen = false
  optionTypeEl.classList.remove("product-options__row--selected");

}

// global and accessible from everywhere @TODO: is it possible to do this in any other way?
window.scrollIntoProperty = function (e) {input.dataset.option_type_id
  var promoBar = document.getElementById("promo-bar");
  var promoBarHeight;

  if (promoBar) {
    promoBar.style.position = "fixed";
    promoBarHeight = promoBar.offsetHeight;
  } else {
    promoBarHeight = 0;
  }

  var topBar = document.getElementById("top-bar");

  var offset = promoBarHeight + topBar.offsetHeight; // sticky nav height
  var elementToScroll = document.querySelector(
    `[data-product="${e.dataset.target}"]`
  );
  window.scroll({
    top: elementToScroll.offsetTop - offset,
    left: 0,
    behavior: "smooth",
  });
};
