document.addEventListener("turbolinks:load", () => {
  mobile_filter_toggler()
})

function mobile_filter_toggler() {

  var filter_button = document.getElementById("mobile-filter")

  if (filter_button) {

    var taxon_bar = document.getElementById("taxon-mobile-filter")

    filter_button.addEventListener("click",function () {

      if(!taxon_bar.classList.contains("taxon-mobile-filter--show")){
        taxon_bar.classList.add("taxon-mobile-filter--show")
        filter_button.style.backgroundColor="white"
        filter_button.style.color="#1f007f"

      }else{
          taxon_bar.classList.remove("taxon-mobile-filter--show")
          filter_button.style.backgroundColor="#1f007f"
          filter_button.style.color="white"
      }
    })  
  }
}
