document.addEventListener('turbolinks:load', () => {
  if ($('#checkout_edit').length === 0) return
  // Get all radio buttons
  const radio_buttons = document.getElementsByName('order[shipments_attributes][0][selected_shipping_rate_id]')

  radio_buttons.forEach(function (radio_button) {
    radio_button.onchange = (ev) => refresh_order_summary(ev)
  })
})

function refresh_order_summary(ev) {
  // Used to try to fix the bug
  ev.preventDefault()

  const selected_option = ev.target.value
  // Necessary to know wich type of delivery was choosen
  document.getElementById('rate_link_' + selected_option).click()

}