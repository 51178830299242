// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require jquery_ujs
//= require turbolinks
//= require_tree .
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import 'bootstrap'
import './shared/index'
import { Application } from '@hotwired/stimulus'
import CookieConsentController from './controllers/cookie_consent_controller'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Stimulus = Application.start()
Stimulus.register('cookie-consent', CookieConsentController)
