document.addEventListener("turbolinks:load", () => {
  if ($(window).width() > 768) {
    fixed_nav()
  } else {
    fixed_nav_mobile()
  }
})

window.onload = function () {
  if ($(window).width() > 768) {
    fixed_nav()
  } else {
    fixed_nav_mobile()
  }

  $(window).on('resize', function () {
    if ($(window).width() > 768) {
      fixed_nav()
    } else {
      fixed_nav_mobile()
    }
  });
}



function fixed_nav() {

  var promoBar = document.getElementById("promo-bar")
  var promoBarHeight

  if (promoBar) {
    promoBar.style.position = "fixed";
    promoBarHeight = promoBar.offsetHeight
  } else {
    promoBarHeight = 0;
  }

  var topBarPrimary = document.getElementById("top-bar-primary")
  topBarPrimary.style.position = null;
  topBarPrimary.style.width = null;
  topBarPrimary.style.paddingTop = null
  topBarPrimary.style.paddingBottom = null
  topBarPrimary.style.top = promoBarHeight + "px"

  var topBarSecondary = document.getElementById("top-bar-secondary")
  topBarSecondary.style.position = "relative";
  topBarSecondary.style.display= null;
  topBarSecondary.style.marginTop = null

  var topBar = document.getElementById("top-bar")
  topBar.style.position = "fixed";
  topBar.style.top = promoBarHeight + "px"

  var middleBar = document.getElementById("middle-bar")

  middleBar.style.marginTop = promoBarHeight + topBar.offsetHeight + "px"

}

function fixed_nav_mobile() {

  var promoBar = document.getElementById("promo-bar")
  var promoBarHeight

  if (promoBar) {
    promoBar.style.position = "fixed";
    promoBarHeight = promoBar.offsetHeight
  } else {
    promoBarHeight = 0;
  }

  var topBar = document.getElementById("top-bar")
  topBar.style.position = null;
  topBar.style.top = null

  var middleBar = document.getElementById("middle-bar")
  middleBar.style.marginTop = null

  var topBarRow = document.getElementById("topbar-row")
  primaryWidth = topBarRow.offsetWidth

  var topBarPrimary = document.getElementById("top-bar-primary")
  topBarPrimary.style.position = "fixed";
  topBarPrimary.style.width = primaryWidth + "px"
  topBarPrimary.style.top = promoBarHeight + "px"
  topBarPrimary.style.paddingTop = "16px"
  topBarPrimary.style.paddingBottom = "16px"

  var topBarSecondary = document.getElementById("top-bar-secondary")
  topBarSecondary.style.position = "relative";
  topBarSecondary.style.display="none";

  var middleBar = document.getElementById("middle-bar")
  middleBar.style.marginTop = promoBarHeight + topBarPrimary.offsetHeight + topBarSecondary.offsetHeight +"px"
  
}
