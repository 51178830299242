class Simulator {
  /**
   * Responsible for the update of the price information.
   * @param {*} simulatorEl 
   */
  constructor(simulatorEl) {
    this.simulatorEl = simulatorEl
    document.addEventListener('simulation-data-updated', this.onDataUpdate.bind(this))
  }

  onDataUpdate(ev) {
    let jres = ev.detail

    this.simulatorEl.innerHTML = "";

    this.buildSections(jres)
  }

  buildSections(jres) {
    this.buildDetailsSection(jres["details"]);
    if (jres["price_per_m2"]) {
      this.buildPriceM2Section(jres["price_per_m2"]);
      this.buildMarginSection(jres["margin_sqm"], "Margem por m2");
    }
    if (jres["price_per_unit"]) {
      this.buildPriceUnitSection(jres["price_per_unit"]);
      this.buildMarginSection(jres["margin_unit"], "Margem por unidade");
    }

    this.buildWeightCostSection(jres["weight_price"]);
    this.buildTotalSection(jres['price']);
  }

  buildDetailsSection(details) {
    let section = this.buildSection("Detalhes");
    for (let key in details) {
      section.appendChild(this.buildSimpleLine(details[key]))
    }
    this.simulatorEl.appendChild(section);
  }

  buildPriceM2Section(m2Prices) {
    let section = this.buildSection("Preço por m2");
    for (let subSection of m2Prices)
      section.appendChild(this.buildSubSection(subSection))
    this.simulatorEl.appendChild(section);
  }

  buildPriceUnitSection(unitPrices) {
    if (!unitPrices) return;
    let section = this.buildSection("Preço por unidade");
    for (let subSection of unitPrices)
      section.appendChild(this.buildSubSection(subSection))
    this.simulatorEl.appendChild(section);
  }

  buildMarginSection(margin, title) {
    if (margin == null) return;

    let section = this.buildSection(title);
    section.appendChild(this.buildSimpleLine(margin))
    this.simulatorEl.appendChild(section);
  }

  buildWeightCostSection(weightPrice) {
    let section = this.buildSection("Peso");
    for (let line of weightPrice)
      section.appendChild(this.buildSimpleLine(line))
    this.simulatorEl.appendChild(section);
  }

  buildTotalSection(price) {
    let section = this.buildSection("Total");
    section.appendChild(this.buildSimpleLine(["Total", price['total'] + " €"]))
    this.simulatorEl.appendChild(section);
  }

  buildSection(titleText) {
    let div = document.createElement('div');
    div.classList.add('simulator__wrapper');
    let title = document.createElement('p');
    title.classList.add('simulator__title');
    title.innerHTML = titleText;
    div.appendChild(title);

    return div;
  }

  buildSubSection(line) {
    let wrapper = document.createElement('div')
    wrapper.classList.add('simulator__wrapper--field', 'simulator__wrapper--border')
    let subtitle = document.createElement('p');
    subtitle.classList.add('simulator__subtitle');
    subtitle.innerText = line[0];
    wrapper.appendChild(subtitle);
    wrapper.appendChild(this.buildLine(line.slice(1)))

    return wrapper;
  }

  buildSimpleLine(data) {
    let [presentation, value] = data;
    let line = document.createElement('p');
    line.classList.add('simulator__line');

    line.appendChild(this.buildSpan(presentation, 'font-weight--medium'))
    line.appendChild(this.buildSpan(value, 'simulator__final-value'))

    return line;

  }

  buildLine(cells) {
    let line = document.createElement('p');
    line.classList.add('simulator__line');

    line.appendChild(this.buildSpan(cells[0], 'font-weight--medium'))
    line.appendChild(this.buildSpan(cells[1], 'simulator__value'))

    let finalValueEl = this.buildSpan(cells[2], 'simulator__final-value')
    if (cells.length == 4) {
      switch (cells[3]['clamped']) {
        case "min":
          finalValueEl.style.color = "green";
          // finalValueEl.prepend(this.buildSmall("(min value)"))
          break;
        case "max":
          finalValueEl.style.color = "red";
          // finalValueEl.prepend(this.buildSmall("(max value)"))
          break;
        default:
          break;
      }
    }
    line.appendChild(finalValueEl)

    return line;
  }

  buildSpan(text, ...classList) {
    let span = document.createElement('span');
    span.classList.add(classList);
    span.innerHTML = text;
    return span;
  }

  buildSmall(text) {
    let small = document.createElement("small");
    small.innerHTML = text
    return small;
  }
}

export { Simulator };