document.addEventListener("turbolinks:load", () => {
  if($(".field_with_errors").length){
    $( ".field_with_errors" ).each(function( index ) {
      $(this).find(".input-field__input").on('input', function() { 
        $(this).closest('.field_with_errors').removeClass("field_with_errors")
      });
      $(this).find(".switch").on('click', function() { 
        $(this).closest('.field_with_errors').removeClass("field_with_errors")
      });
    });
  }
});
