document.addEventListener("turbolinks:load", () => {
  mb_way_toggler()
})

window.onload = function () {
  var mbwayInput = $("#mbw input")
  var mbway_form = document.getElementById("payment-form")
  if (mbwayInput) {
    if (mbwayInput.is(':checked')) {
      mbway_form.style.display = "block"
    }
  }
}

function mb_way_toggler() {

  var mbway = document.getElementById("mbw")
  var mbway_form= document.getElementById("payment-form")

  if (mbway) {

    var payment_select=document.getElementsByClassName("select-box")
    mbway_form.style.display="none"

    for (let i = 0; i < payment_select.length; i++) {
      payment_select[i].addEventListener('click', function () {
        if(payment_select[i].id == "mbw"){ 
          mbway_form.style.display="block"
        }else{
          mbway_form.style.display="none"
        }
      })
    }
  }
  // Prevents mb-way form from showing when mbway is not an available payment method for user/admin.
  else if (mbway_form) {
    mbway_form.style.display="none"
  }
}