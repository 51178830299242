document.addEventListener("turbolinks:load", () => {
  function intlConfig(id, countries) {
    var intl = intlTelInput(id, {
      initialCountry: "pt",
      preferredCountries: ["pt"],
      onlyCountries: countries,
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js",
      // any initialisation options go here
    });
  }

  //shipping
  const shipping_phone = document.querySelector(
    "#order_ship_address_attributes_phone"
  );
  const billing_phone = document.querySelector(
    "#order_bill_address_attributes_phone"
  );

  if (shipping_phone) {
    intlConfig(shipping_phone, ["pt", "it", "es", "gb"]);
  }

  if (billing_phone) {
    intlConfig(billing_phone, ["pt", "it", "es", "gb"]);
  }

  $("#checkout_form_address").submit(function () {
    var ship = window.intlTelInputGlobals.getInstance(shipping_phone);
    var bill = window.intlTelInputGlobals.getInstance(billing_phone);

    $(shipping_phone).val(ship.getNumber());
    $(billing_phone).val(bill.getNumber());
  });

  //account

  const user_phone = document.querySelector("#user_phone");

  if (user_phone) {
    intlConfig(user_phone, ["pt", "it", "es", "gb"]);
  }

  $("#user_edit").submit(function () {
    var account = window.intlTelInputGlobals.getInstance(user_phone);

    $(user_phone).val(account.getNumber());
  });

  //payment

  const mbway_phone = document.querySelector("#mbway_phone");

  if (mbway_phone) {
    var intl = intlTelInput(mbway_phone, {
      initialCountry: "pt",
      preferredCountries: [""],
      onlyCountries: ["pt"],
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js",
      // any initialisation options go here
    });
  }

  $("#checkout_form_payment").submit(function () {
    var phonemb = window.intlTelInputGlobals.getInstance(mbway_phone);

    $(mbway_phone).val(phonemb.getNumber());
  });
});
