document.addEventListener('turbolinks:load', () => {
  const menu_btn = document.querySelector('#menu-btn')
  const side_menu_btn = document.querySelector('#side-menu-btn')
  const sidebar = document.querySelector('#sidebar')

  side_menu_btn.addEventListener('click', () => {
    sidebar.classList.toggle('active-nav')
    sidebar.classList.toggle('side-navbar--hidden')
  })

  menu_btn.addEventListener('click', () => {
    sidebar.classList.toggle('active-nav')
    sidebar.classList.toggle('side-navbar--hidden')
  })

  // const localeSelectEl = document.querySelector('#locale')
  // localeSelectEl.addEventListener('change', async (e) => {
  //   const data = new FormData()
  //   data.append('switch_to_locale', localeSelectEl.value)
  //   console.log(fetch('/locale/set', {
  //     method: 'POST',
  //     body: data
  //   }))
  // })
})
