document.addEventListener('turbolinks:load', () => {
  if ($('#cart').length === 0) { return }

  const myCarousels = document.querySelectorAll('.carousel')

  if (myCarousels.length === 0) return

  myCarousels.forEach((carousel) => {
    carousel.addEventListener('slide.bs.carousel', function (e) {
      const id = e.target.id
      const frontLabel = document.getElementById(`${id}_front_label`)
      const backLabel = document.getElementById(`${id}_back_label`)
      if (e.to == 1) {
        // segundo
        frontLabel.style.display = 'none'
        backLabel.style.display = ''
      } else {
        frontLabel.style.display = ''
        backLabel.style.display = 'none'
      }
    })
    carousel.dispatchEvent(new Event('slide.bs.carousel'))
  })
})
