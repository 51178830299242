document.addEventListener("turbolinks:load", () => {
  sidebarFlow()
})

function sidebarFlow() {

  var sideBar = document.getElementById("sidebar")

  if (sideBar) {

    $('.side-navbar__primary-item').click(function () {
      $(this).find('.side-navbar__product-wrapper').addClass('active')
      $(this)
        .siblings('li')
        .find('.side-navbar__product-wrapper')
        .removeClass('active')
      $(this).find('.side-navbar__link').addClass('active')
      $(this)
        .siblings('li')
        .find('.side-navbar__link')
        .removeClass('active')
    })

    $('.side-navbar__primary-item').find(".side-navbar__secondary-item:first").click(function (e) {
      $(this).closest(".side-navbar__product-wrapper").removeClass('active')
      e.stopPropagation()
    })
  }
}
