document.addEventListener('turbolinks:load', () => {
  let selectorId
  if ($('#signup').length !== 0) {
    selectorId = 'spree_user_role_users_attributes_0_role_id'
  } else if ($('#users_edit').length !== 0) {
    selectorId = 'user_role_users_attributes_0_role_id'
  } else return

  registerFieldsetToggle(selectorId)
})

function registerFieldsetToggle (selectorId) {
  // We keep the original style display values
  const segmentEl = document.getElementById('segment')
  const volumeEl = document.getElementById('sales_volume')

  // TODO: this elemnt is hardcoded, the options too. Doesnt seem right. Ask for alternative
  const selector = document.getElementById(selectorId)
  if(selector){
    selector.onchange = ev => {
      const selectedOption = ev.target.value
      switch (selectedOption) {
        case '2': // Individual
          disableFieldset(segmentEl)
          disableFieldset(volumeEl)
          break
        case '3': // Company
          enableFieldset(segmentEl)
          enableFieldset(volumeEl)
          break
        case '4': // Retailer
          disableFieldset(segmentEl)
          enableFieldset(volumeEl)
          break
        case '5': // Sole proprietor
          disableFieldset(segmentEl)
          disableFieldset(volumeEl)
          break
      }
    }
    // Update on first load
    selector.dispatchEvent(new Event('change'))
  }

}

function enableFieldset (el) {
  el.style.display = ''
  el.removeAttribute('disabled')
}

function disableFieldset (el) {
  el.style.display = 'none'
  el.setAttribute('disabled', '')
}
