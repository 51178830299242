document.addEventListener("turbolinks:load", () => {
  if ($('#product-details').length === 0) { return }

  const plusIcon = document.getElementById('quantity-plus')
  const minusIcon = document.getElementById('quantity-minus')
  const inputEl = document.querySelector('.input-field__input--quantity')

  plusIcon?.addEventListener('click', () => {
    inputEl.value++;
    inputEl.dispatchEvent(new Event('change'))
  })

  minusIcon?.addEventListener('click', () => {
    if (inputEl.value > 1) {
      inputEl.value--;
      inputEl.dispatchEvent(new Event('change'))
    }
  })
})