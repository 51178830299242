class Select {
  constructor(selectBlockEl) {
    this.element = selectBlockEl
    this.selectEl = selectBlockEl.querySelector(
      "[selector='hiddenSelect'] select"
    )
    this.bodyEl = selectBlockEl.querySelector("[selector='body']")
    this.placeholderEl = this.bodyEl.querySelector("[selector='placeholder']")
    this.optionsWrapperEl = this.bodyEl.querySelector(
      "[selector='optionsWrapper']"
    )

    this.element.addEventListener('click', this.toggle.bind(this))
    this.render()
  }

  render() {
    this.renderPlaceholder()
    this.renderOptions()
  }

  renderPlaceholder() {
    this.placeholderEl.innerText = this.selectedOptionEL.innerText
  }

  renderOptions() {
    this.optionsWrapperEl.innerHTML = null

    Array.from(this.selectEl.options).forEach((optionEl) => {
      // Dont render options that have no value attribute
      if (!optionEl.value) return

      const isSelected = optionEl.value === this.selectedOptionEL.value

      const optionSpanEl = document.createElement('span')
      optionSpanEl.classList.add('custom-option')
      optionSpanEl.innerText = optionEl.innerText
      optionSpanEl.dataset.value = optionEl.value
      optionSpanEl.addEventListener('click', () => {
        if (!isSelected) {
          this.selectEl.value = optionEl.value
          this.selectEl.dispatchEvent(new Event('change'))
          this.render()
        }
      })

      this.optionsWrapperEl.appendChild(optionSpanEl)
    })
  }

  toggle() {
    this.bodyEl.classList.toggle('open')
  }

  close() {
    this.bodyEl.classList.remove('open')
  }

  get selectedOptionEL() {
    return this.selectEl.selectedOptions[0]
  }
}

document.addEventListener('turbolinks:load', () => {
  const selectBlockEls = document.querySelectorAll('.custom-select-block')
  const selects = Array.from(selectBlockEls).map(
    (selectBlockEl) => new Select(selectBlockEl)
  )

  document.addEventListener("updateStateFinished", () => {
    selects.forEach((s) => s.render())
  })

  window.addEventListener('click', (e) => {
    const ancestralSelectBlockEl = e.target.closest('.custom-select-block')
    const selectsToClose = selects.filter(
      (select) => select.element !== ancestralSelectBlockEl
    )
    selectsToClose.forEach((select) => select.close())
  })

})
