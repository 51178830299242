function realTimeShowUpdate() {
  var countdown = $("#countdown");

  if (countdown) {
    var days = $("#countdown .days");
    var daysText = $("#countdown .days-text");
    var hours = $("#countdown .hours");
    var hoursText = $("#countdown .hours-text");
    var minutes = $("#countdown .minutes");
    var minutesText = $("#countdown .minutes-text");
    var seconds = $("#countdown .seconds");
    var secondsText = $("#countdown .seconds-text");

    if (days.text() == "0") {
      days.hide();
      daysText.hide();
    }

    if (hours.text() == "00") {
      hours.hide();
      hoursText.hide();
    }

    if (hours.text() == "00" && minutes.text() == "00") {
      minutes.hide();
      minutesText.hide();
    }

    if (minutes.text() == "00" && seconds.text() == "00") {
      seconds.hide();
      secondsText.hide();
    }
  }
}

function getTimeRemaining(endTime) {
  const total =
    Date.parse(new Date(parseFloat(endTime))) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

document.addEventListener("turbolinks:load", () => {
  const clock = document.getElementById("countdown");

  if (clock) {
    updateClock();
    const timeinterval = setInterval(updateClock, 1000);
  }

  function updateClock() {
    let endTime = document.getElementById("hidden_expires_date");

    if (endTime) endTime = endTime.getAttribute("data-expire_date");
    const t = getTimeRemaining(endTime);
    const daysSpan = clock.querySelector(".days");
    const hoursSpan = clock.querySelector(".hours");
    const minutesSpan = clock.querySelector(".minutes");
    const secondsSpan = clock.querySelector(".seconds");

    if (daysSpan) daysSpan.innerHTML = t.days;
    if (hoursSpan) hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
    if (minutesSpan) minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
    if (secondsSpan) secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);
    realTimeShowUpdate();

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }
});
