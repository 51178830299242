// // TODO: extract the PreviewUploader to another file. Will do this the next time i need an uploader

class PreviewUploader {
  constructor(elem) {
    this.elem = elem;
    this.frontUploadEl = document.getElementById('front_upload')
    this.backUploadEl = document.getElementById('back_upload')
    this.backUploadEl.addEventListener(
      'uploader-count-changed',
      this.onUploaderCountChangeHandler.bind(this)
    )
    this.errorMessageEl = document.getElementById("upload_error")

    this.has2pagePdf = false

    // Loaded via <script> tag, create shortcut to access PDF.js exports.
    this.pdfjsLib = window['pdfjs-dist/build/pdf']
    // The workerSrc property shall be specified.
    this.pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.js'
    this.uploaders = this.elem.querySelectorAll('.product-options__uploader')
    this.firstUploader = this.uploaders[0]
    this.secondUploader = this.uploaders[1]
    this.uploaders.forEach(uploader => {
      uploader.npages = 0
      uploader.addEventListener('change', (e) => this.uploaderOnChangeHandler(e, uploader))
    });
  }

  uploaderOnChangeHandler(e, uploader) {
    const file = e.target.files[0]
    // console.log("uploader on change")
    // console.log(`necessary pdfs: ${this.necessaryPdfs}`)
    if (file && file.type === 'application/pdf') {
      this.showSpinner(uploader)

      const fileReader = new FileReader()
      fileReader.onload = (_e) => { this.fileReaderOnLoadHandler(uploader, fileReader) };
      fileReader.readAsArrayBuffer(file)
    }
  }

  onUploaderCountChangeHandler(e) {
    // console.log("Uploaders reset")
    this.uploaders.forEach(u => this.hideImage(u))
    this.refreshSelectedStyle()
  }

  refreshSelectedStyle() {
    this.elem.classList.remove('product-options__row--not-selected')
    if (this.necessaryPdfs == this.uploadedPdfs)
      this.elem.classList.add('product-options__row--selected')
    else
      this.elem.classList.remove('product-options__row--selected')
  }

  setNotSelectedStyle() {
    this.elem.classList.add('product-options__row--not-selected')
  }

  get chosen() {
    const chosen = this.uploadedPdfs == this.necessaryPdfs;
    if (!chosen) this.setNotSelectedStyle()

    return chosen;
  }

  get necessaryPdfs() {
    return this.elem.querySelectorAll(".product-options__uploader:not([disabled])").length
  }

  get uploadedPdfs() {
    return this.uploaders[0].npages + this.uploaders[1].npages;
  }

  showRemoveFileButton(uploader) {
    if (!uploader.closeButton) {
      uploader.closeButton = this.createCloseButton(uploader)
      uploader.parentElement.appendChild(uploader.closeButton)
    }
    uploader.closeButton.style.display = '';
  }

  hideRemoveFileButton(uploader) {
    if (!uploader.closeButton) return;

    uploader.closeButton.setAttribute('style', 'display: none !important');
  }

  createCloseButton(uploader) {
    let i = document.createElement('i');
    i.classList.add("fas", "fa-times", "remove-button")
    i.onclick = (e) => {
      e.preventDefault()
      e.stopPropagation()

      this.hideImage(uploader)
      this.refreshSelectedStyle()
    }
    i.style
    return i;
  }

  showSpinner(uploader) {
    this.getLoadingEl(uploader).style.display = 'flex'
  }

  hideSpinner(uploader) {
    this.getLoadingEl(uploader).style.display = 'none';
  }

  getLoadingEl(el) {
    return el.parentElement.parentElement.querySelector('.product-spinner')
  }

  showImage(uploader) {
    let imageEl = this.getImageElement(uploader);
    imageEl.style.display = 'block';
    imageEl.parentElement.classList.add('active');

    this.hideSpinner(uploader)
    this.showRemoveFileButton(uploader)
  }

  hideImage(uploader) {
    let imageEl = this.getImageElement(uploader);
    imageEl.style.display = 'none';
    imageEl.parentElement.classList.remove('active');

    this.resetUploader(uploader)
    this.hideSpinner(uploader)
    this.hideRemoveFileButton(uploader)
  }

  showError() {
    this.errorMessageEl.style.display = 'block';
  }

  hideError() {
    this.errorMessageEl.style.display = 'none';
  }

  resetUploader(uploader) {
    uploader.value = null
    uploader.npages = 0
  }

  getImageElement(uploader) {
    return uploader.parentElement.querySelector('.product-options__canvas-image');
  }

  async fileReaderOnLoadHandler(uploader, fileReader) {
    const pdfData = new Uint8Array(fileReader.result)
    // Using DocumentInitParameters object to load binary data.
    const loadingTask = this.pdfjsLib.getDocument({ data: pdfData })

    try {
      const pdf = await loadingTask.promise
      // console.log(`Loaded pdf pages/need: ${pdf.numPages}/${this.necessaryPdfs}`)
      this.hideError()

      if (this.necessaryPdfs == 1) {
        if (pdf.numPages == 1) {
          this.renderPagePreview(pdf.getPage(1), uploader);
        } else {
          this.showError()
          this.hideImage(uploader);
        }
      } else { //2
        if (this.has2pagePdf || pdf.numPages == 2) {
          if (uploader == this.firstUploader)
            this.hideImage(this.secondUploader)
          else
            this.hideImage(this.firstUploader)

          this.has2pagePdf = false
        }
        if (pdf.numPages == 1) {
          this.renderPagePreview(pdf.getPage(1), uploader);
        } else if (pdf.numPages == 2) {
          this.has2pagePdf = true
          this.renderPagePreview(pdf.getPage(1), this.firstUploader);
          this.renderPagePreview(pdf.getPage(2), this.secondUploader);
        } else {
          this.showError();
          this.hideImage(uploader);
        }
      }
    } catch (error) {
      console.error(error)
    }

    this.refreshSelectedStyle()
  }

  async renderPagePreview(pagePromise, uploader) {
    uploader.npages = 1
    const page = await pagePromise
    const scale = 1
    const viewport = page.getViewport({ scale: scale })

    // Prepare canvas using PDF page dimensions
    const imageElement = this.getImageElement(uploader)

    const canvas = imageElement.querySelector('.canvas-file')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    }
    const renderTask = page.render(renderContext)

    await renderTask.promise

    this.showImage(uploader)
  }
}

document.addEventListener('turbolinks:load', () => {
  const uploader = document.getElementById('product-img-uploader')
  if (uploader)
    uploader.uploader = new PreviewUploader(uploader)
})
