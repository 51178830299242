document.addEventListener("turbolinks:load", () => {
  orders_toggler()
  address_option_modal()
  order_buttons()
  addresses_toggler()
})

function addresses_toggler() {

  var addressButton = document.getElementById("address-manager-button")

  if (addressButton) {

    addressButton.onclick = function () {

      var address_list = document.getElementsByClassName("account-edit__addresses-list")
      var show_orders = document.getElementById("show-addresses")
      var hide_orders = document.getElementById("hide-addresses")

      show_orders.style.display = "none"
      hide_orders.style.display = "none"

      if (address_list.length > 3) {
        show_orders.style.display = "inline-flex"
        for (let i = 3; i < address_list.length; i++) {
          address_list[i].style.display = "none"
        }
        show_orders.onclick = function () {
          for (let i = 3; i < address_list.length; i++) {
            address_list[i].style.display = "inline-flex"
          }
          show_orders.style.display = "none"
          hide_orders.style.display = "inline-flex"
        }
        hide_orders.onclick = function () {
          for (let i = 3; i < address_list.length; i++) {
            address_list[i].style.display = "none"
          }
          hide_orders.style.display = "none"
          show_orders.style.display = "inline-flex"
        }
      }
    };
  }
}

function orders_toggler() {

  var ordersButton = document.getElementById("account-orders-button")

  if (ordersButton) {
    ordersButton.onclick = function () {

      var orders_list = document.getElementsByClassName("account-edit__orders-list")
      var show_orders = document.getElementById("show-orders")
      var hide_orders = document.getElementById("hide-orders")

      show_orders.style.display = "none"
      hide_orders.style.display = "none"

      if (orders_list.length > 3) {
        show_orders.style.display = "inline-flex"
        for (let i = 3; i < orders_list.length; i++) {
          orders_list[i].style.display = "none"
        }
        show_orders.onclick = function () {
          for (let i = 3; i < orders_list.length; i++) {
            orders_list[i].style.display = "inline-flex"
          }
          show_orders.style.display = "none"
          hide_orders.style.display = "inline-flex"
        }
        hide_orders.onclick = function () {
          for (let i = 3; i < orders_list.length; i++) {
            orders_list[i].style.display = "none"
          }
          hide_orders.style.display = "none"
          show_orders.style.display = "inline-flex"
        }
      }
    };
  }
}

function address_option_modal() {

  var addressButton = document.getElementById("address-manager-button")

  if (addressButton) {

    addressButton.addEventListener('click', function () {

      var addresses_list = document.getElementsByClassName("account-edit__address-list-element")

      for (let i = 0; i < addresses_list.length; i++) {
        
        addresses_list[i].querySelector('.fa-ellipsis-h').addEventListener('click', function () {

          addresses_list[i].querySelector('.fa-ellipsis-h').style.color = "$c-dark-grey"
          //modal postion set


          let bodyRect = document.body.getBoundingClientRect()

          let boundBox = addresses_list[i].querySelector('.fa-ellipsis-h').getBoundingClientRect();
          let coordX = boundBox.left;
          let coordY = boundBox.top;

          var option_modal = addresses_list[i].nextElementSibling
          option_modal.style.display = "block"

          option_modal.style.left = (coordX - bodyRect.left - 305).toString() + "px";
          option_modal.style.top = (coordY - bodyRect.top).toString() + "px";

          //adjust modal on resize

          window.addEventListener('resize', function (event) {
            let bodyRect = document.body.getBoundingClientRect()

            let boundBox = addresses_list[i].querySelector('.fa-ellipsis-h').getBoundingClientRect();
            let coordX = boundBox.left;
            let coordY = boundBox.top;

            var option_modal = addresses_list[i].nextElementSibling

            option_modal.style.left = (coordX - bodyRect.left - 305).toString() + "px";
            option_modal.style.top = (coordY - bodyRect.top).toString() + "px";
          }, true);

          //close modal

          document.addEventListener('click', function (event) {
            var option_modal_open = addresses_list[i].nextElementSibling
            if (option_modal_open !== event.target && !option_modal_open.contains(event.target) && addresses_list[i].querySelector('.fa-ellipsis-h') !== event.target) {
              option_modal_open.style.display = "none"
              addresses_list[i].querySelector('.fa-ellipsis-h').style.color = "$c-grey"
            }
          });
        })
      }
    })
  }
}

function order_buttons() {

  var going_button = document.getElementById("order-going-button")
  var history_button = document.getElementById("order-history-button")

  if (going_button && history_button) {

    going_button.addEventListener('click', function () {
      if (!going_button.classList.contains('button--primary')) {
        history_button.classList.remove('button--primary')
        history_button.classList.add('button--secondary')
        going_button.classList.add('button--primary')
        going_button.classList.remove('button--secondary')
      }
    })

    history_button.addEventListener('click', function () {
      if (!history_button.classList.contains('button--primary')) {
        going_button.classList.remove('button--primary')
        going_button.classList.add('button--secondary')
        history_button.classList.add('button--primary')
        history_button.classList.remove('button--secondary')
      }
    })
  }
}
